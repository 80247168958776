@mixin flexCenter($direction: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

//adds max-width and padding for a page's content
//top padding is a map containing a dektop and mobile val
@mixin layoutContainer($topPadding: $pageContentTopPadding, $leftRightPadding: $pageContentHorizPadding) {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  min-height: 100vh;
  padding: map-get($topPadding, 'desktop') $leftRightPadding 0 $leftRightPadding;

  @include isTablet() {
    padding-top: map-get($topPadding, 'tablet');
  }
}

@mixin textOfCartActions {
  letter-spacing: 0.47px;
  color: var(--text-color-dark);
  font-weight: 500;
  @include fontSize(26px);
}

@mixin priceOfCartActions {
  @include fontFamily(var(--secondary-font-family));
  font-weight: 600;
  letter-spacing: -0.31px;
  color: var(--price-text-color);
  @include fontSize(26px);
}


@mixin cardStyling() {
  border: 2px solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  box-shadow: 0px 4px 8px var(--shadow-color);
  overflow: hidden;

  @include isTablet() {
    border: 1px solid var(--card-border-color);
    border-radius: var(--input-border-radius-base-mobile);
  }
}

@mixin pointyTriangleOnDiv() {
  &::before {
    height: 22px;
    width: 22px;
    top: -11px;
    @include left(50%);
    border: inherit;
    background-color: inherit;
    content: '';
    position: absolute;
    transform: translate(-50%) rotate(-45deg);
    clip-path: polygon(
      100% 0,
      3% 0,
      100% 97%
    ); //makes is so you only see a triangle of the div
    @content;
  }
}

@mixin inputStyle {
  background-color: var(--input-bg);
  height: calculateRem(64px);
  color: var(--secondary-color);
  font-weight: 400;
  text-align: left;
  box-shadow: none;
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius-base);
}

@mixin multilineEllipsis() {
  /* styles for '...' */
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;

  /* create the ... */
  &::before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }
  /* hide ... if we have text, which is less than or equal to max lines */
  &::after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
  }
}

@mixin elemHoverEffect() {
  opacity: 1;
  transition: 0.2s;

  &:hover {
    opacity: 0.75;
    @content;
  }
}

/*
  applies the map values to the appropriate viewport using the defined property name
*/
@mixin applyViewportMap($property_name, $map, $top: true, $right: true, $bottom: true, $left: true) {
  @if map-has-key($map, 'desktop') {
    @if $top and $right and $bottom and $left {
      #{$property_name}: map-get($map, 'desktop');
    }
    @else {
      @if $top {
        #{$property_name}-top: map-get($map, 'desktop');
      }
      @if $right {
        [dir=ltr] & {
          #{$property_name}-right: map-get($map, 'desktop');
        }

        [dir=rtl] & {
          #{$property_name}-left: map-get($map, 'desktop');
        }
      }
      @if $bottom {
        #{$property_name}-bottom: map-get($map, 'desktop');
      }
      @if $left {
        [dir=ltr] & {
          #{$property_name}-left: map-get($map, 'desktop');
        }
        
        [dir=rtl] & {
          #{$property_name}-right: map-get($map, 'desktop');
        }
      }
    }
  }

  @if map-has-key($map, 'tablet') {
    @include isTablet() {
      @if $top and $right and $bottom and $left {
        #{$property_name}: map-get($map, 'tablet');
      }
      @else {
        @if $top {
          #{$property_name}-top: map-get($map, 'tablet');
        }
        @if $right {
          [dir=ltr] & {
            #{$property_name}-right: map-get($map, 'tablet');
          }
          
          [dir=rtl] & {
            #{$property_name}-left: map-get($map, 'tablet');
          }
        }
        @if $bottom {
          #{$property_name}-bottom: map-get($map, 'tablet');
        }
        @if $left {
          [dir=ltr] & {
            #{$property_name}-left: map-get($map, 'tablet');
          }
          
          [dir=rtl] & {
            #{$property_name}-right: map-get($map, 'tablet');
          }
        }
      }
    } 
  }

  @if map-has-key($map, 'mobile') {
    @include isMobile() {
      @if $top and $right and $bottom and $left {
        #{$property_name}: map-get($map, 'mobile');
      }
      @else {
        @if $top {
          #{$property_name}-top: map-get($map, 'mobile');
        }
        @if $right {
          [dir=ltr] & {
            #{$property_name}-right: map-get($map, 'mobile');
          }
          
          [dir=rtl] & {
            #{$property_name}-left: map-get($map, 'mobile');
          }
        }
        @if $bottom {
          #{$property_name}-bottom: map-get($map, 'mobile');
        }
        @if $left {
          [dir=ltr] & {
            #{$property_name}-left: map-get($map, 'mobile');
          }
          
          [dir=rtl] & {
            #{$property_name}-right: map-get($map, 'mobile');
          }
        }
      }
    } 
  }
}

@mixin flipProperty($ltr-property, $rtl-property, $value) {
  [dir=ltr] & {
    #{$ltr-property}: $value;
  }

  [dir=rtl] & {
    #{$rtl-property}: $value;
  }
}

@mixin left($value) {
  @include flipProperty('left', 'right', $value);
}

@mixin right($value) {
  @include flipProperty('right', 'left', $value);
}

@mixin marginLeft($value) {
  @include flipProperty('margin-left', 'margin-right', $value);
}

@mixin marginRight($value) {
  @include flipProperty('margin-right', 'margin-left', $value);
}

@mixin paddingLeft($value) {
  @include flipProperty('padding-left', 'padding-right', $value);
}

@mixin paddingRight($value) {
  @include flipProperty('padding-right', 'padding-left', $value);
}

@mixin borderLeft($value) {
  @include flipProperty('border-left', 'border-right', $value);
}

@mixin borderRight($value) {
  @include flipProperty('border-right', 'border-left', $value);
}
