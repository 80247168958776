
.loading-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__splash {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      animation: imgLoading 1s infinite;
      object-fit: cover;
      overflow: hidden;
    }
  }
 
  &__container {
    &--fancy {
      margin: 50px;
      height: 50px;
      width: 50px;
      animation: spin 1.5s linear infinite;
  
      @keyframes spin {
        0% {
          transform: rotateZ(0);
        }

        100% {
          transform: rotateZ(360deg);
        }
      }
    }
   
    &--simple {
      position: relative;
      width: 20%;
      min-width: 20px;
      max-width: 40px;
      border: 4px solid rgba(255, 255, 255, 0.3);
      border-top: 4px solid var(--body-background-secondary); /* Blue */
      border-radius: 50%;
      animation: spin 2s linear infinite;

      &::after { //used to keep the spinner into a square at any width
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }

    &--dots {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 2rem 0;
      margin: 0 -5%;
      
      .dot-stretching {
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background-color: var(--primary-color);
        color: var(--primary-color);
        transform: scale(1.25, 1.25);
        animation: dotStretching 2s infinite ease-in;
      }
      
      .dot-stretching::before,
      .dot-stretching::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }
      
      .dot-stretching::before {
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background-color: var(--primary-color);
        color: var(--primary-color);
        animation: dotStretchingBefore 2s infinite ease-in;
      }
      
      .dot-stretching::after {
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background-color: var(--primary-color);
        color: var(--primary-color);
        animation: dotStretchingAfter 2s infinite ease-in;
      }
      
      @keyframes dotStretching {
        0% {
          transform: scale(1.25, 1.25);
        }

        50%,
        60% {
          transform: scale(0.8, 0.8);
        }

        100% {
          transform: scale(1.25, 1.25);
        }
      }
      
      @keyframes dotStretchingBefore {
        0% {
          transform: translate(0) scale(0.7, 0.7);
        }

        50%,
        60% {
          transform: translate(-30px) scale(1, 1);
        }

        100% {
          transform: translate(0) scale(0.7, 0.7);
        }
      }
      
      @keyframes dotStretchingAfter {
        0% {
          transform: translate(0) scale(0.7, 0.7);
        }

        50%,
        60% {
          transform: translate(30px) scale(1, 1);
        }

        100% {
          transform: translate(0) scale(0.7, 0.7);
        }
      }
    }
  }
}

@keyframes imgLoading {
  0% {
    transform: scale(1.01);
    opacity: 0.9;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.01);
    opacity: 0.9;
  }
}
