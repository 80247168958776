@import './styleConstants';
//takes in something like 14px as a parameter and sets the font size in rem
@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

@mixin lineHeight($height) {
  line-height: $height;
}

@mixin letterSpacing($space) {
  letter-spacing: $space;
}

@mixin fontFamily($family, $size: '', $letterSpacing: '', $lineHeight: '') {
  font-family: $family, $font-family-fallback;

  @if isMap($size) {
    @include applyViewportMap(font-size, $size); 
  } 
  @else if  $size != '' {
    @include fontSize($size);
  }

  @if isMap($letterSpacing) {
    @include applyViewportMap(letter-spacing, $letterSpacing); 
  } 
  @else if  $letterSpacing != '' {
    @include letterSpacing($letterSpacing);
  }

  @if isMap($lineHeight) {
    @include applyViewportMap(line-height, $lineHeight); 
  } 
  @else if  $lineHeight != '' {
    @include lineHeight($lineHeight);
  }
}
