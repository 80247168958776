//fallback font
$font-family-fallback: sans-serif, -apple-system, system-ui, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';

// z-indexes
$zindex1: 100; //things just over the normal page for whatever reason
$zindex2: 200; //the menu bar
$zindex3: 300; //popup backdrops
$zindex4: 400; //popups & overlays

$maxContentWithd: 1140px;

//breakpoints for media queryes
$mediaQueryBreakpoints: ('desktop': 1200px, 'landscapetablet': 991px, 'tablet': 767px, 'largeMobile': 576px, 'mobile': 480px);


//paddings (should be multiples of 4)
$padding-xs: ('desktop': calculateRem(6px), 'tablet': calculateRem(4px));
$padding-s: ('desktop':  calculateRem(12px), 'tablet': calculateRem(8px));
$padding-m: ('desktop':  calculateRem(20px), 'tablet': calculateRem(16px));
$padding-ml: ('desktop': calculateRem(32px), 'tablet': calculateRem(24px));
$padding-l: ('desktop': calculateRem(46px), 'tablet': calculateRem(32px), 'mobile': calculateRem(20px));
$padding-xl: ('desktop': calculateRem(94px), 'tablet': calculateRem(44px));

//title font sizes, some are clearly unused
$titleSize-xs: ('desktop': calculateRem(20px), 'tablet': calculateRem(16px));
$titleSize-s: ('desktop': calculateRem(26px), 'tablet': calculateRem(18px));
$titleSize-m: ('desktop': calculateRem(34px), 'tablet': calculateRem(24px));
$titleSize-l: ('desktop': calculateRem(44px), 'tablet': calculateRem(28px));
$titleSize-xl: ('desktop': calculateRem(58px), 'tablet': calculateRem(32px));

//text sizes
$textSize-xs: ('desktop': calculateRem(16px), 'tablet': calculateRem(14px));
$textSize-s: ('desktop': calculateRem(18px), 'tablet': calculateRem(18px));
$textSize-m: ('desktop': calculateRem(22px), 'tablet': calculateRem(18px));
$textSize-l: ('desktop': calculateRem(26px), 'tablet': calculateRem(20px));
$textSize-xl: ('desktop': calculateRem(32px), 'tablet': calculateRem(24px));
$textSize-xxl: ('desktop': calculateRem(36px), 'tablet': calculateRem(36px));

//line heights
$lineHeight-s: 20px;
$lineHeight-m: 24px;
$lineHeight-l: 32px;
$lineHeight-xl: 40px;

//letter spacing
$letterSpacing-s: -0.25px;
$letterSpacing-m: -0.5px;
$letterSpacing-l: -1px;

//line style: lines used everywhere in the website
$lineStyle: 1px solid var(--card-border-color);

$inputPaddingSize: $padding-m;
$inputTextSize: $textSize-m;
$inputIconSize: $textSize-l;



$pageContentTopPadding: $padding-xl;
$pageContentHorizPadding: calculateRem(30px);
//could be used for any card's padding

$listRowMargins: ('desktop':  calculateRem(20px), 'tablet': calculateRem(16px));

$cardContentPadding: $padding-ml;
$summaryCardPadding: $padding-ml;
