//this file holds all our breakpoints using mixins
@mixin isMobile() {
  @media only screen and (max-width: map-get($mediaQueryBreakpoints, 'mobile')) {
    @content;
  }
}


@mixin isLargeMobile() {
  @media only screen and (max-width: map-get($mediaQueryBreakpoints, 'largeMobile')) {
    @content;
  }
}

@mixin isTablet() {
  @media screen and (max-width: map-get($mediaQueryBreakpoints, 'tablet')) {
    @content;
  }
}


@mixin isTabletAndUp() {
  @media screen and (min-width: map-get($mediaQueryBreakpoints, 'tablet')) {
    @content;
  }
}


@mixin isLandscapetablet() {
  @media screen and (max-width: map-get($mediaQueryBreakpoints, 'landscapetablet')) {
    @content;
  }
}

@mixin isDesktop() {
  @media screen and (max-width: map-get($mediaQueryBreakpoints, 'desktop')) {
    @content;
  }
}
